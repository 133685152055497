import { createBrowserRouter } from 'react-router-dom'

import LayoutDefault from '@/components/layouts/LayoutDefault'
import LayoutDashboard from '@/components/layouts/LayoutDashboard'
import Home from '@/views/Home'
import EarnReward from '@/views/EarnReward'
import { DefaultRoute } from '@/constants/types'
import Overview from '@/views/Overview'
import Payment from '@/views/Payment'
import LayoutRoot from '@/components/layouts/LayoutRoot'
import LinkManagement from '@/views/LinkManagement'
import Policy from '@/views/Policy'
import Support from '@/views/Support'
import AdminOverview from '@/views/AdminOverview'
import AdminUserDetail from '@/views/AdminUserDetail'
import AdminPayment from '@/views/AdminPayment'
import Loading from '@/components/Loading'

export const authRoutes = [
  DefaultRoute.Dashboard,
  DefaultRoute.Overview,
  DefaultRoute.Payment,
  DefaultRoute.LinkManageMent,
  DefaultRoute.AdminOverview,
  DefaultRoute.UserDetail,
  DefaultRoute.WithdrawalManage,
]

export const adminRoutes = [
  DefaultRoute.AdminOverview,
  DefaultRoute.UserDetail,
  DefaultRoute.WithdrawalManage,
]

const router = createBrowserRouter([
  {
    path: DefaultRoute.Homepage,
    element: <LayoutRoot />,
    children: [
      {
        path: DefaultRoute.Login,
        element: <Loading />,
      },
      {
        path: DefaultRoute.Homepage,
        element: <LayoutDefault />,
        children: [
          {
            path: DefaultRoute.Homepage,
            element: <Home />,
          },
        ],
      },
      {
        path: DefaultRoute.EarnReward,
        element: <EarnReward />,
      },
      {
        path: DefaultRoute.Dashboard,
        element: <LayoutDashboard />,
        children: [
          {
            path: DefaultRoute.Overview,
            element: <Overview />,
          },
          {
            path: DefaultRoute.Payment,
            element: <Payment />,
          },
          {
            path: DefaultRoute.LinkManageMent,
            element: <LinkManagement />,
          },
          {
            path: DefaultRoute.Policy,
            element: <Policy />,
          },
          {
            path: DefaultRoute.Support,
            element: <Support />,
          },
          // Admin //
          {
            path: DefaultRoute.AdminOverview,
            element: <AdminOverview />,
          },
          {
            path: DefaultRoute.UserDetail,
            element: <AdminUserDetail />,
          },
          {
            path: DefaultRoute.WithdrawalManage,
            element: <AdminPayment />,
          },
        ],
      },
    ],
  },
])

export default router
