import {
  createContext,
  useState,
  useEffect,
  useCallback,
  FC,
  PropsWithChildren,
  useContext,
} from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'

import { adminRoutes, authRoutes } from '@/routers'
import http from '@/utils/http'
import { DefaultRoute, LocalStorageKey } from '@/constants/types'
import { useIsHerondBrowser } from './BrowserCheckProvider'
import {
  CredentialsType,
  getAccessToken,
  setCredential,
} from '@/utils/credentials'
import { AuthAPI } from '@/constants/api'

interface User {
  userId: number
  email: string | undefined
  profilePicture: string
  hasReferralCode: boolean
  name: string
  isAdmin?: boolean
}

interface RefreshToken {
  refreshToken: string
}

interface IPropsCallbackLogin {
  redirectIfFail?: boolean
}

interface AuthContextType {
  user: User | undefined
  isAuthChecking: boolean
  setUser(userInfor?: object | undefined): void
  login(propsLogin: IPropsCallbackLogin): void
}

const defaultValue: AuthContextType = {
  user: undefined,
  isAuthChecking: false,
  setUser: () => {},
  login: () => {},
}

export const AuthContext = createContext<AuthContextType>(defaultValue)

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('Please use AuthContext in parent component')
  }

  return {
    user: context.user,
    isAuthChecking: context.isAuthChecking,
    setUser: context.setUser,
    login: context.login,
  }
}

export const AuthProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props
  const isHerondBrowser = useIsHerondBrowser()
  const isLogin = !!getAccessToken()
  const [isAuthChecking, setIsAuthChecking] = useState<boolean>(false)
  const [user, setUser] = useState<User | undefined>(() => {
    const userLocal = localStorage.getItem(LocalStorageKey.USER_PROFILE)
    if (userLocal) {
      return JSON.parse(userLocal)
    }
    return undefined
  })
  const [triggerRefreshToken, setTriggerRefreshToken] = useState(0)

  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const refreshToken = searchParams.get('token')

  const login = useCallback(
    async (propsLogin: IPropsCallbackLogin) => {
      const { redirectIfFail } = propsLogin
      const isAdminRoute = adminRoutes.find((x) => x === location.pathname)

      try {
        setIsAuthChecking(true)
        const res = await http.get<User, undefined>(`/profile/me`)

        if (res.success && res.data) {
          localStorage.setItem(
            LocalStorageKey.USER_PROFILE,
            JSON.stringify({ ...res.data, isAdmin: false }),
          )

          const fakeResponse = { ...res.data, isAdmin: false }
          setUser(fakeResponse)
        }

        if (isAdminRoute && !res?.data?.isAdmin) {
          navigate(DefaultRoute.Homepage)
          return
        }

        if (res.success) {
          navigate(DefaultRoute.Dashboard)
        }
      } catch (e) {
        localStorage.removeItem(LocalStorageKey.USER_PROFILE)
        setUser(undefined)
        if (redirectIfFail) {
          navigate(DefaultRoute.Homepage)
        }
      }
      setIsAuthChecking(false)
    },
    [navigate],
  )

  useEffect(() => {
    if (refreshToken && !isLogin) {
      ;(async () => {
        try {
          const res = await http.post<CredentialsType, RefreshToken>(
            AuthAPI.refresh,
            {
              refreshToken: refreshToken,
            },
          )
          if (res.data?.accessToken) {
            setCredential(res.data)
            setTriggerRefreshToken(Math.floor(Math.random() * 1000))
          }
        } catch {
          navigate(DefaultRoute.Homepage)
        }
      })()
    }
  }, [refreshToken])

  useEffect(() => {
    if (isHerondBrowser && isLogin) {
      const authRoute = authRoutes.find((x) => x === location.pathname)
      const isAuthRoute = !!authRoute
      login({ redirectIfFail: isAuthRoute })
    }
  }, [isHerondBrowser, triggerRefreshToken])

  return (
    <>
      <AuthContext.Provider value={{ user, setUser, login, isAuthChecking }}>
        {children}
      </AuthContext.Provider>
    </>
  )
}

export default AuthProvider
