import { FC, useMemo } from 'react'
import styled, { css } from 'styled-components'

import config from '@/config'
import useModel from '@/context/ModelContext'

import ModelDownLoad from './ModelDownLoad'
import { Link } from 'react-router-dom'
import { useIsHerondBrowser } from '@/context/BrowserCheckProvider'
import { CircularProgress } from '@mui/material'
import { useAuth } from '@/context/AuthProvider'
import { useTranslation } from 'react-i18next'

const styleLink = css`
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 220px;
  color: white;
  font-weight: 500;
  height: 50px;
  border-radius: 40px !important;
  text-transform: capitalize !important;
  font-size: 1rem;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  & > div {
    z-index: 1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:before {
    transition: all 200ms ease;
    content: '';
    position: absolute;
    inset: 0;
    z-index: 0;

    background: linear-gradient(264.35deg, #ff821c 45.5%, #ffc46b 142.06%);
  }
  &:hover {
    &:before {
      opacity: 0.9;
    }
  }
`

const ButtonStyled = styled(Link)`
  ${styleLink}
`

const ButtonStyledA = styled.a`
  ${styleLink}
`

const EarningButton: FC = () => {
  const { user, isAuthChecking } = useAuth()
  const useModal = useModel()
  const isHerondBrowser = useIsHerondBrowser()
  const { t } = useTranslation()

  const loginLink = useMemo(() => {
    return `${config.baseApiUrl}/auth/login`
  }, [])

  const handleOpenModalDownload = () => {
    useModal.setModelContent(<ModelDownLoad />)
    useModal.showModal()
  }

  if (!isHerondBrowser) {
    return (
      <ButtonStyledA onClick={handleOpenModalDownload}>
        <div>{t('home.startEarning')}</div>
      </ButtonStyledA>
    )
  }

  if (isAuthChecking) {
    return (
      <ButtonStyledA target="_self" href="#">
        <CircularProgress color="info" size="25px" style={{ color: 'white' }} />
      </ButtonStyledA>
    )
  }

  if (!user) {
    return (
      <ButtonStyledA target="_self" href={loginLink}>
        <div>{t('home.loginHerond')}</div>
      </ButtonStyledA>
    )
  }

  return (
    <ButtonStyled to="/dashboard">
      <div>{t('home.goToDashBoard')}</div>
    </ButtonStyled>
  )
}

export default EarningButton
