export const replaceStringToEmail = (
  string: string,
  target: string,
  link: string,
) => {
  const regex = new RegExp('\\b(' + target + ')\\b', 'g')
  if (!string.match(regex)) return string
  const arr = string.split(regex)
  const result = arr.map((item) => {
    if (item !== target) {
      return <span>{item}</span>
    } else {
      return (
        <a href={link} target="__blank">
          {item}
        </a>
      )
    }
  })
  return result
}
