import { Spin } from 'antd'
import styled from 'styled-components'

const LoadingWrap = styled.div`
  width: 100%;
  height: 100vh;
  background: #eeeeee;
  position: relative;
`

const LoadingIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Loading = () => {
  return (
    <LoadingWrap>
      <LoadingIcon>
        <Spin />
      </LoadingIcon>
    </LoadingWrap>
  )
}

export default Loading
