import useModel from '@/context/ModelContext'
import LinkManagementService from '@/services/linkManagementService'
import images from '@/utils/images'
import { useState } from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import { MdOutlineError } from 'react-icons/md'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import { useAddToast } from '@/state/application/hooks'
import { CircularProgress } from '@mui/material'
import Button from '@/components/Button'
import { useTranslation } from 'react-i18next'
import { ErrorCode } from '@/constants/errorCode'

const CloseButton = styled.div`
  display: flex;
  position: absolute;
  top: 15px;
  right: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;

  &:hover {
    cursor: pointer;
    background-color: #e4e4e4;
    border-radius: 20%;
  }
`

const RequestModalStyled = styled.section`
  position: fixed;
  z-index: 100;
  padding: 30px;
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  width: 550px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  @media (max-width: 576px) {
    padding: 20px;
    width: calc(100vw - 60px);
  }
`

const RequestContent = styled.div`
  display: flex;
  flex-direction: column;
  & > h2 {
    font-size: 1.125rem;
    font-weight: 1.6;
    font-weight: 500;
    margin-bottom: 30px;
  }

  & > h6 {
    font-size: 14px;
    font-weight: 500;
    line-height: 160%;
    margin: 0;
  }

  .request-input {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #a1a9b5;
    padding-left: 20px;
    box-sizing: border-box;
    color: #7a818c;
    margin-bottom: 10px;

    @media (max-width: 576px) {
      padding-left: 10px;
    }

    &.accept {
      border-color: #298cfd;
    }

    &.error {
      border-color: #f42b2b;
    }

    & > span {
      width: 1px;
      height: 30px;
      background-color: #dce0e3;
    }

    & > input {
      outline: none;
      border: none;
      font-size: 16px;
      color: #202226;

      @media (max-width: 576px) {
        flex: 1;
      }

      &::placeholder {
        color: #a1a9b5;
      }
    }
  }

  & > .status {
    margin-bottom: 20px;
    height: 35px;

    & > small {
      display: flex;
      gap: 5px;
      font-size: 14px;

      &.error {
        color: #f42b2b;
      }

      &.ok {
        color: #298cfd;
      }

      svg {
        font-size: 16px;
      }
    }
  }

  & > button {
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    color: white;
    border-radius: 6px;
    outline: none;
    border: none;
    font-size: 16px;
    background-color: #298cfd;
    cursor: pointer;
    &:disabled {
      background-color: #98c4fa;
      cursor: not-allowed;
    }
  }

  .buttonContainer {
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    width: 180px;
    color: white;
    border-radius: 6px;
    outline: none;
    border: none;
    font-size: 16px;
    background-color: #298cfd;
    cursor: pointer;
    &:disabled {
      background-color: #98c4fa;
      cursor: not-allowed;
    }
  }
`

interface PropsType {
  callBackAfterCreateDone?: VoidFunction
}

const ErrorCodeDefine = {
  [ErrorCode.ELIMITTIME]: {
    message: 'linkList.msgErrorLimited',
  },
  [ErrorCode.EBADWORD]: {
    message: 'linkList.msgErrorSensitive',
  },
  [ErrorCode.EMAXCODE]: {
    message: 'linkList.msgErrorMaxCode',
  },
} as {
  [key: string]: {
    message: string
  }
}

export const CreateNewLinkPopup = (props: PropsType) => {
  const { callBackAfterCreateDone } = props
  const addToast = useAddToast()
  const { t } = useTranslation()
  const useModal = useModel()
  const hideModal = () => {
    useModal.setModelContent(undefined)
    useModal.hideModal()
  }

  const [errMessage, setErrMessage] = useState<string | undefined>()
  const [acceptStatus, setAcceptStatus] = useState<boolean>()
  const [customLink, setCustomLink] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const createLink = async () => {
    try {
      setIsLoading(true)
      const params = {
        referralCode: customLink,
      }
      const createLinkRes =
        await LinkManagementService.postCreateLinkAffiliateData(params)
      const { message = '', success } = createLinkRes || {}

      if (callBackAfterCreateDone !== undefined && success) {
        callBackAfterCreateDone()
      } else {
        const errorMessage =
          (message || t('linkList.msgErrorInvalid')) ?? 'Unknown error occurred'
        throw new Error(String(errorMessage))
      }
    } catch (e) {
      const errorMessage =
        e instanceof Error
          ? e.message
          : t('linkList.msgUnexpectedError') ?? 'An unexpected error occurred'

      addToast({
        msg: String(errorMessage),
      })
      setAcceptStatus(false)
      setErrMessage(String(errorMessage))
    } finally {
      setIsLoading(false)
    }
  }

  const checkCodeExist = async (customLinkProps: string) => {
    setIsLoading(true)
    const body = {
      referralCode: customLinkProps,
    }
    const checkingLinkRes =
      await LinkManagementService.postCheckingLinkAffiliateData(body)
    if (checkingLinkRes?.success) {
      setAcceptStatus(true)
      setErrMessage(undefined)
    } else {
      setAcceptStatus(false)
      const msgError = checkingLinkRes?.code
        ? t(ErrorCodeDefine[checkingLinkRes.code]?.message)
        : checkingLinkRes?.message
      if (msgError) {
        setErrMessage(msgError)
      } else {
        setErrMessage(checkingLinkRes?.message)
      }
    }

    setIsLoading(false)
  }

  const handleClick = () => {
    createLink()
  }

  const validateInput = (valueInput: string) => {
    let isValid = false
    if (valueInput.length < 5 || valueInput.length > 15) {
      setErrMessage(
        t('linkList.theCodeMustBeBetween') ||
          'The code must be between 5 and 15 characters long',
      )
      setAcceptStatus(false)
    } else {
      setErrMessage(undefined)
      isValid = true
    }

    return isValid
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeInput = (valueInput: any) => {
    if (valueInput.target.value) {
      const isValid = validateInput(valueInput.target.value)
      if (isValid) {
        checkCodeExist(valueInput.target.value)
      }
    }
    setCustomLink(valueInput.target.value)
  }

  return (
    <RequestModalStyled onClick={(e) => e.preventDefault()}>
      <CloseButton onClick={hideModal}>
        <img
          src={images.closeButtonIcon}
          alt=""
          style={{ width: '20px', height: '20px' }}
        />
      </CloseButton>
      <RequestContent>
        <h2>{t('linkList.createNewLink')}</h2>
        <h6>{t('linkList.customLink')}</h6>
        <div
          className={`${acceptStatus ? 'accept' : ''} ${
            errMessage ? 'error' : ''
          } request-input`}
        >
          <p>herond.org?ref=</p>
          <span></span>
          <input
            style={{ flex: 1 }}
            onChange={debounce((e) => onChangeInput(e), 500)}
            type="text"
            placeholder={
              t('linkList.enterYourCustomURL') || 'Enter Your Custom URL'
            }
          />
        </div>
        <div className="status">
          {errMessage ? (
            <small className="error">
              {isLoading ? (
                <CircularProgress size={15} color="inherit" />
              ) : (
                <MdOutlineError />
              )}{' '}
              {errMessage}
            </small>
          ) : (
            ''
          )}
          {acceptStatus ? (
            <small className="ok">
              {isLoading ? (
                <CircularProgress size={15} color="inherit" />
              ) : (
                <AiFillCheckCircle />
              )}
              {t('linkList.thisURLCanBeUsed')}
            </small>
          ) : (
            ''
          )}
        </div>
        <Button
          disabled={
            !acceptStatus ||
            isLoading ||
            customLink.length < 5 ||
            customLink.length > 15
          }
          title={t('add') || 'Add'}
          isLoading={isLoading}
          onClick={handleClick}
          classCss="buttonContainer"
        />
      </RequestContent>
    </RequestModalStyled>
  )
}
