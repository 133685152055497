import {
  LinkManagementAPI,
  LinkAffiliateArrType,
  ReferralCode,
} from '@/constants/api'
import http from '@/utils/http'

export default class LinkManagementService {
  static getLinkAffiliateData = async () => {
    try {
      const res = await http.get<LinkAffiliateArrType[]>(
        LinkManagementAPI.getCode,
      )

      if (res.success && res.data) {
        return res.data
      }
    } catch (e) {
      //
    }
    return undefined
  }

  static postCreateLinkAffiliateData = async (params: ReferralCode) => {
    try {
      const res = await http.post<boolean, unknown>(
        LinkManagementAPI.createCode,
        params,
      )

      return res
    } catch (e) {
      //
    }
    return undefined
  }

  static putUpdatePrimaryRefLinkAffiliateData = async (
    params: ReferralCode,
  ) => {
    try {
      const res = await http.put<boolean, unknown>(
        LinkManagementAPI.updatePrimaryCode(params.referralCode),
      )

      return res
    } catch (e) {
      //
    }
    return undefined
  }

  static postCheckingLinkAffiliateData = async (params: ReferralCode) => {
    try {
      const res = await http.post<boolean, unknown>(
        LinkManagementAPI.checkingCode,
        params,
        {},
      )

      return res
    } catch (e) {
      //
    }
    return undefined
  }

  static postDeleteLinkAffiliateData = async (code: string) => {
    try {
      const res = await http.delete<boolean, unknown>(
        `${LinkManagementAPI.deleteCode}/${code}`,
      )

      return res
    } catch (e) {
      //
    }
    return undefined
  }
}
