import { LOCAL_KEYS } from '@/constants/storages'
import { LocalStorage, SessionStorage } from './storages'

export interface CredentialsType {
  accessToken: string
  refreshToken: string
  expiresIn: number
}

export const getAccessToken = () =>
  LocalStorage.get(LOCAL_KEYS.ACCESS_TOKEN) ||
  SessionStorage.get(LOCAL_KEYS.ACCESS_TOKEN)

export const setCredential = (credentials: CredentialsType) => {
  const { accessToken, refreshToken, expiresIn } = credentials || {
    accessToken: '',
    refreshToken: '',
    expiresIn: 0,
  }
  if (!accessToken || !refreshToken) {
    throw new Error('Missing data')
  }

  const convertExpiration = new Date().getTime() + expiresIn * 1000

  LocalStorage.set(LOCAL_KEYS.ACCESS_TOKEN, accessToken)
  LocalStorage.set(LOCAL_KEYS.REFRESH_TOKEN, refreshToken)
  LocalStorage.set(LOCAL_KEYS.EXPIRATION_TOKEN, convertExpiration)
}
