import axios from 'axios'

import store from '@/state'
import { addToast } from '@/state/application/reducer'
import { DefaultRoute, LocalStorageKey } from '@/constants/types'

import {
  ErrorHandler,
  ErrorHandlerMany,
  ErrorHandlerObject,
  HttpData,
  THttpError,
} from './types'
import { useContext } from 'react'
import { AuthContext } from '@/context/AuthProvider'
import { useNavigate } from 'react-router'

declare module 'axios' {
  export interface AxiosRequestConfig {
    raw?: boolean
    silent?: boolean
  }
}

// type guard to identify that is an ErrorHandlerObject
function isErrorHandlerObject(
  value: ErrorHandlerObject,
): value is ErrorHandlerObject {
  if (typeof value === 'object') {
    return ['message', 'after', 'before', 'notify'].some((k) => k in value)
  }
  return false
}

export function showToastError(msg: string) {
  store.dispatch(addToast({ msg }))
}

class ErrorHandlerRegistry {
  private handlers = new Map<string, ErrorHandler | undefined>()

  private parent: ErrorHandlerRegistry | null = null

  constructor(
    parent: ErrorHandlerRegistry = undefined!,
    input?: ErrorHandlerMany,
  ) {
    if (typeof parent !== 'undefined') this.parent = parent
    if (typeof input !== 'undefined') this.registerMany(input)
  }

  // allow to register an handler
  register(key: string, handler: ErrorHandler) {
    this.handlers.set(key, handler)
    return this
  }

  // unregister a handler
  unregister(key: string) {
    this.handlers.delete(key)
    return this
  }

  // search a valid handler by key
  find(seek: string): ErrorHandler | undefined {
    const handler = this.handlers.get(seek)
    if (handler) return handler
    return this.parent?.find(seek)
  }

  // pass an object and register all keys/value pairs as handler.
  registerMany(input: ErrorHandlerMany) {
    for (const [key, value] of Object.entries(input)) {
      this.register(key, value)
    }
    return this
  }

  // handle error seeking for key
  handleError(
    this: ErrorHandlerRegistry,
    seek: (string | undefined)[] | string,
    error: THttpError,
  ): boolean {
    if (Array.isArray(seek)) {
      return seek.some((key) => {
        if (key !== undefined) return this.handleError(String(key), error)
      })
    }
    const handler = this.find(String(seek))
    if (!handler) {
      return false
    } else if (typeof handler === 'string') {
      return this.handleErrorObject(error, { message: handler })
    } else if (typeof handler === 'function') {
      const result = handler(error)
      if (isErrorHandlerObject(result as ErrorHandlerObject))
        return this.handleErrorObject(error, result as ErrorHandlerObject)
      return !!result
    } else if (isErrorHandlerObject(handler)) {
      return this.handleErrorObject(error, handler)
    }
    return false
  }

  // if the error is an ErrorHandlerObject, handle here
  handleErrorObject(error: THttpError, options: ErrorHandlerObject = {}) {
    options?.before?.(error, options)
    showToastError(options.message ?? 'Unknown Error!!')
    return true
  }

  // this is the function that will be registered in interceptor.
  resposeErrorHandler(
    this: ErrorHandlerRegistry,
    error: THttpError,
    direct?: boolean,
  ) {
    if (error === null) throw new Error('Unrecoverrable error!! Error is null!')
    if (axios.isAxiosError(error)) {
      if (error.code === 'ERR_NETWORK') {
        return this.handleErrorObject(error, {
          message: error.message,
        })
      }

      const response = error?.response
      const config = error?.config
      const data = response?.data as HttpData

      if (response?.status === 401) {
        const navigate = useNavigate()
        const authContext = useContext(AuthContext)
        localStorage.removeItem(LocalStorageKey.USER_PROFILE)
        authContext.setUser(undefined)
        navigate(DefaultRoute.Homepage)
        return
      }

      if (!direct && config?.raw) throw error
      const seekers = [
        data?.code,
        error.code,
        error?.name,
        String(data?.status),
        String(response?.status),
      ]
      const result = this.handleError(seekers, error)

      if (!result) {
        if (data?.code && data?.description) {
          return this.handleErrorObject(error, {
            message: data?.description,
          })
        }
      }
    } else if (error instanceof Error) {
      return this.handleError(error.name, error)
    }
    //if nothings works, throw away
    throw error
  }
}

// create ours globalHandlers object
export const globalHandlers = new ErrorHandlerRegistry()

export function dealWith(solutions: ErrorHandlerMany, ignoreGlobal?: boolean) {
  let global
  if (ignoreGlobal === false) global = globalHandlers
  const localHandlers = new ErrorHandlerRegistry(global, solutions)
  return (error: THttpError) => localHandlers.resposeErrorHandler(error, true)
}
