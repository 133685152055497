import axios, {
  AxiosResponse,
  AxiosInstance,
  AxiosRequestConfig,
  isAxiosError,
} from 'axios'

import config from '@/config'

import { dealWith, showToastError } from './errorHandlers'
import { HttpError, HttpResponse } from './types'
import { LocalStorage } from '../storages'
import { getAccessToken, setCredential } from '../credentials'
import { LOCAL_KEYS } from '@/constants/storages'
import { HTTP_STATUS_CODE } from '@/constants/httpCode'
import { AuthAPI } from '@/constants/api'

const abortController = new AbortController()

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  showToast?: boolean
  contentType?: string
}

export const NUMBER_RETRIES = 3
let retryCount = 0
let retryMethod: Promise<string> | null = null
let tmpNewAccessToken: string | null = null

function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function timeDelay(k: number) {
  const baseInterval = 0.5
  const baseMultiplier = 1.5
  const retryInterval = baseInterval * baseMultiplier ** (k - 1) * 1000
  const max = k === NUMBER_RETRIES ? 500 : retryInterval
  return retryInterval + randomInt(0, max)
}

function sleep(delay = 2000): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, delay))
}

function sleepRefreshToken(
  delay = 50,
  firstResolve?: (value: string | null) => void,
) {
  setTimeout(() => new Promise((resolve) => resolve(null)), 10000)
  return new Promise((resolve) =>
    setTimeout(async () => {
      const currentResolve = firstResolve || resolve
      if (tmpNewAccessToken) {
        setTimeout(() => currentResolve(tmpNewAccessToken), 0)
      } else {
        await sleepRefreshToken(delay, currentResolve)
      }
    }, delay),
  )
}

function resetRetry() {
  retryCount = 0
  retryMethod = null
}

const refresh = async (
  refreshToken: string,
  numberRetry = 0,
): Promise<string> => {
  try {
    tmpNewAccessToken = null
    const params = {
      refreshToken,
    }
    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await axios.post(
      `${config.baseApiUrl}${AuthAPI.refresh}`,
      params,
      headers,
    )
    const results = res?.data || {}
    setCredential(results)
    return await Promise.resolve(results?.accessToken)
  } catch (error) {
    if (
      numberRetry < NUMBER_RETRIES &&
      isAxiosError(error) &&
      error?.response?.status &&
      error?.response?.status >= HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR
    ) {
      await sleep(timeDelay(retryCount + 1))
      return refresh(refreshToken, numberRetry + 1)
    }

    if (
      isAxiosError(error) &&
      error?.response?.status &&
      [
        HTTP_STATUS_CODE.BAD_REQUEST,
        HTTP_STATUS_CODE.UNAUTHORIZED,
        HTTP_STATUS_CODE.FORBIDDEN,
      ].includes(error?.response?.status)
    ) {
      LocalStorage.clearAll()
      window.location.href = '/'
    }
    return Promise.reject(error)
  }
}

// this interceptor is used to handle all success ajax request
// we use this to check if status code is 200 (success), if not, we throw an HttpError
// to our error handler take place.
function responseHandler(response: AxiosResponse) {
  const configResponse = response?.config
  if (configResponse.raw) {
    return response
  }
  if (response.status === HTTP_STATUS_CODE.SUCCESS) {
    const data = response?.data
    if (!data) {
      throw new HttpError('API Error. No data!')
    }
    return response
  }

  throw new HttpError('API Error! Invalid status code!')
}

class Http {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: config.baseApiUrl,
      timeout: 10000,
    })

    this.axiosInstance.interceptors.request.use(
      async (configAxios: CustomAxiosRequestConfig) => {
        // Attach auth header using token from local storage
        const accessToken = tmpNewAccessToken || getAccessToken()
        const languageCode = LocalStorage.get(LOCAL_KEYS.LANGUAGE_TOKEN, 'en')
        const refreshToken = LocalStorage.get(LOCAL_KEYS.REFRESH_TOKEN)
        const expiration = LocalStorage.get(LOCAL_KEYS.EXPIRATION_TOKEN)

        const newConfig = {
          ...configAxios,
          headers: {
            ...configAxios.headers,
            ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
            'accept-language': languageCode,
            'Content-Type': configAxios?.contentType || 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        }

        const isExpired = Date.now() >= new Date(expiration).getTime()
        if (isExpired) {
          if (refreshToken && !retryMethod) {
            retryCount += 1
            retryMethod = refresh(refreshToken)
              .finally(() => {
                retryMethod = null
              })
              .catch((error: unknown) => {
                if (isAxiosError(error)) {
                  return Promise.reject(error)
                }
                return Promise.reject(new Error('Unexpected error occurred'))
              })
            const token = await retryMethod
            tmpNewAccessToken = token
            newConfig.headers.Authorization = `Bearer ${token}`
            return newConfig
          }
          const res = await sleepRefreshToken()
          resetRetry()
          if (res) {
            return {
              ...configAxios,
              headers: {
                ...configAxios.headers,
                ...(res && { Authorization: `Bearer ${res}` }),
                'accept-language': languageCode,
                'show-toast':
                  configAxios?.showToast !== undefined
                    ? configAxios.showToast
                    : true,
                'Content-Type': configAxios?.contentType || 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
              },
            }
          }
          abortController.abort()
        }
        return newConfig
      },
    )
    this.axiosInstance.interceptors.response.use(
      (resp) => {
        responseHandler(resp)
        return resp
      },
      async (err) => {
        const origReqConfig = err.config
        if (
          err?.response?.status >= HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR &&
          retryCount < NUMBER_RETRIES
        ) {
          retryCount += 1
          return sleep(timeDelay(retryCount)).then(() =>
            this.axiosInstance.request(origReqConfig),
          )
        }

        if (err?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
          const refreshToken = LocalStorage.get(LOCAL_KEYS.REFRESH_TOKEN) || ''
          if (refreshToken && !retryMethod && retryCount < NUMBER_RETRIES) {
            retryCount += 1
            delete origReqConfig.headers.Authorization

            retryMethod = refresh(refreshToken)
              .finally(() => {
                retryMethod = null
              })
              .catch((error: unknown) => {
                if (isAxiosError(error)) {
                  return Promise.reject(error)
                }
                return Promise.reject(new Error('Unexpected error occurred'))
              })
            const token = await retryMethod
            tmpNewAccessToken = token
            origReqConfig.headers.Authorization = `Bearer ${token}`
            return this.axiosInstance(origReqConfig)
          }
          const res = await sleepRefreshToken()
          resetRetry()
          if (res) {
            origReqConfig.headers.Authorization = `Bearer ${res}`
            return this.axiosInstance(origReqConfig)
          }
          abortController.abort()
        }
        if (err?.response?.status === HTTP_STATUS_CODE.FORBIDDEN) {
          showToastError('Permission denied for this action.')
        }
        const showToast = err?.config?.headers?.['show-toast']
        const errorData = err?.response?.data || {}
        if (err?.response && showToast) {
          showToastError(
            errorData.detail ||
              errorData.message ||
              errorData.error ||
              'Something went wrong!',
          )
        }
        const errorMessage =
          errorData.errors?.[0]?.description ||
          errorData?.detail ||
          errorData?.Detail ||
          (typeof errorData === 'string' && errorData) ||
          'Some thing went wrong!'

        const customError: HttpError = {
          ...errorData,
          message: errorMessage,
        }

        return Promise.reject(customError)
      },
    )
  }

  handleResponse(res: AxiosResponse) {
    if (
      res.config.url === AuthAPI.refresh &&
      res.status === HTTP_STATUS_CODE.SUCCESS
    ) {
      return { data: res.data }
    }
    if (res.status === HTTP_STATUS_CODE.SUCCESS) {
      return res.data
    }
    if (!res.data.success && res.data.code && res.data.message) {
      dealWith({ [res.data.code]: { message: res.data.message } })
      return { success: false, code: res.data.code, message: res.data.message }
    }
    dealWith({ UNKNOWN_ERROR: { message: 'unknown_error' } })
    return { success: false, code: 'UNKNOWN_ERROR', message: 'UNKNOWN_ERROR' }
  }

  async get<T, D = undefined>(
    url: string,
    configRequest?: AxiosRequestConfig<D>,
  ): Promise<HttpResponse<T>> {
    const res = await this.axiosInstance.get<HttpResponse<T>>(
      url,
      configRequest,
    )
    return this.handleResponse(res)
  }

  async post<T, D>(
    url: string,
    data?: D,
    configRequest?: AxiosRequestConfig<D>,
  ): Promise<HttpResponse<T>> {
    const res = await this.axiosInstance.post<HttpResponse<T>>(
      url,
      data,
      configRequest,
    )
    return this.handleResponse(res)
  }

  async put<T, D>(
    url: string,
    data?: D,
    configRequest?: AxiosRequestConfig<D>,
  ): Promise<HttpResponse<T>> {
    const res = await this.axiosInstance.put<HttpResponse<T>>(
      url,
      data,
      configRequest,
    )
    return this.handleResponse(res)
  }

  async delete<T, D>(
    url: string,
    configRequest?: AxiosRequestConfig<D>,
  ): Promise<HttpResponse<T>> {
    const res = await this.axiosInstance.delete<HttpResponse<T>>(
      url,
      configRequest,
    )
    return this.handleResponse(res)
  }
}

function createHttpInstance() {
  return new Http()
}

export const http: Http = createHttpInstance()

export default http
