import {
  OverViewAPI,
  OverViewDataChartType,
  OverViewDataType,
  OverViewHistoryRecruitedType,
  PaymentAPI,
} from '@/constants/api'
import http from '@/utils/http'
export default class OverviewService {
  static getOverViewData = async () => {
    try {
      const res = await http.get<OverViewDataType>(OverViewAPI.overviewData)

      if (res && res.success && res.data) {
        return res
      }
    } catch (e) {
      //
    }
    return undefined
  }

  static getBalanceData = async () => {
    try {
      const res = await http.get<number>(PaymentAPI.balanceUser)

      if (res && res.success && res.data !== undefined && res.data >= 0) {
        return res.data
      }
    } catch (e) {
      //
    }
    return undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getChartApi = async (params: any) => {
    try {
      const res = await http.get<OverViewDataChartType[]>(
        OverViewAPI.chartData,
        {
          params: params,
        },
      )

      if (res && res.success && res.data) {
        return res.data
      }
    } catch (e) {
      //
    }
    return undefined
  }

  static getLastestCode = async () => {
    try {
      const res = await http.get<string>(OverViewAPI.lastestCode)

      if (res && res.success && res.data) {
        return res
      }
    } catch (e) {
      //
    }
    return undefined
  }

  static getDefaultCode = async () => {
    try {
      const res = await http.get<string>(OverViewAPI.defaultCode)

      if (res && res.success && res.data) {
        return res
      }
    } catch (e) {
      //
    }
    return undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getHistoryApi = async (params: any) => {
    try {
      const res = await http.get<OverViewHistoryRecruitedType>(
        OverViewAPI.historyUser,
        {
          params: params,
        },
      )

      if (res && res.success && res.data) {
        return res.data
      }
    } catch (e) {
      //
    }
    return undefined
  }
}
