export enum LocalStorageKey {
  USER_PROFILE = 'USER_PROFILE',
}

export interface ToastOptions {
  timeout?: number
}

export interface Toast {
  id: string
  msg: string
  options?: ToastOptions
}

export const StatusWithdraw = {
  Waiting: 'Waiting',
  Approved: 'Approved',
  Rejected: 'Rejected',
}

export enum DefaultRoute {
  Homepage = '/',

  // auth
  Dashboard = '/dashboard',
  Overview = '/dashboard/overview',
  Payment = '/dashboard/payment',
  LinkManageMent = '/dashboard/link-management',
  Policy = '/dashboard/policy',
  Support = '/dashboard/support',
  EarnReward = '/earn-reward',
  Login = '/login',

  // admin
  AdminOverview = '/dashboard/admin-overview',
  UserDetail = '/dashboard/user-detail',
  WithdrawalManage = '/dashboard/withdrawal-manage',
}

export interface NavBarLink {
  title: string
  to?: string // make sure "to" property is always a string
  icon: JSX.Element
  isExternal?: boolean
}

export interface ConfigTableType {
  pageIndex: number
  pageSize: number
  totalCount: number
  totalPages: number
}

export interface RecruitedHistoryType {
  id: number
  address: string
  status: string
  createdDateTime: Date
}
